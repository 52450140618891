import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Fab, Typography, Box, Button, List, ListItem, AppBar } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import GetUserDetails from '../services/GetUserData';

function FloatingButton() {
    const defaultSettings = ['Home', 'Login', 'Register', 'Articles'];
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [open, setOpen] = useState(false);
    const [animationState, setAnimationState] = useState('');
    const [settings, setSettings] = useState(defaultSettings);
    const [nickname, setNickname] = useState('');

    useEffect(() => {
        const checkLoginStatus = () => {
            const accessToken = sessionStorage.getItem('accessToken');
            const userRole = sessionStorage.getItem('role');

            if (accessToken) {
                if (!isLoggedIn) {
                    setIsLoggedIn(true);
                    updateSettings(userRole);
                    fetchUserProfile();
                }
            } else {
                if (isLoggedIn) {
                    setIsLoggedIn(false);
                    updateSettings();
                }
            }
        };
        checkLoginStatus();

        const intervalId = setInterval(checkLoginStatus, 1000);
        return () => clearInterval(intervalId);

    }, [isLoggedIn]);

    const fetchUserProfile = async () => {
        const storedUser = sessionStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            const nickname = getUserAttribute(parsedUser, 'nickname');
            try {
                const userDetails = await GetUserDetails(nickname);
                setNickname(userDetails.nickname);
                const role = userDetails.role;
                const cafe = userDetails.cafe;
                sessionStorage.setItem('cafeId', cafe);
                sessionStorage.setItem('role', role);
                updateSettings(role);
            } catch (error) {
                console.error("Error occurred when getting user data", error);
            }
        }
    };

    const getUserAttribute = (user, name) => {
        const attribute = user.UserAttributes.find(attr => attr.Name === name);
        return attribute ? attribute.Value : 'N/A';
    };

    const updateSettings = (role) => {
        if (role === 'USER') {
            setSettings(['Home', 'Profile', 'Edit Profile', 'Write Article', 'Articles', 'Logout'])
        } else if (role === 'CONTRIBUTOR') {
            setSettings(['Home', 'Profile', 'Edit Profile', 'Write Article', 'Contributor', 'Articles', 'Logout']);
        } else {
            setSettings(defaultSettings);
        }
    };
    const handleClick = () => {
        setAnimationState('slideIn');
        setOpen(true);
    };

    const handlePageSelect = (page) => {
        navigateToUrl(getPageUrl(page));
        setAnimationState('slideOut');
        setTimeout(() => {
            setOpen(false);
        }, 500);
    };

    const handleClose = () => {
        setAnimationState('slideOut');
        setTimeout(() => {
            setOpen(false);
        }, 500);
    };

    const getPageUrl = (page) => {
        switch (page) {
            case 'Home':
                return '/';
            case 'Profile':
                return `/profile/${nickname}`;
            case 'Edit Profile':
                return `/profile`;
            case 'Write Article':
                return '/writearticle';
            case 'Contributor':
                return '/contributor';
            case 'Login':
                return '/signin';
            case 'Register':
                return '/signup';
            case 'Articles':
                return '/list';
            case 'Logout':
                handleLogout();
            default:
                return '/';
        }
    };

    const handleLogout = () => {
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('cafeId');
        sessionStorage.removeItem('userCartId');
        sessionStorage.removeItem('role');
        setIsLoggedIn(false);
        setSettings([]);
        navigateToUrl('/');
        window.location.reload();
    };

    return (
        <AppBar>
            <Box
                sx={{
                    position: 'fixed',
                    mx: 5,
                    my: 5,
                    zIndex: 2,
                }}
            >
                <Fab
                    sx={{
                        width: '60px',
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 0,
                        justifyContent: 'center',
                        flexDirection: 'row',
                        background: 'black',
                        '&:hover': {
                            background: 'black',
                        },

                    }}
                    onClick={handleClick}
                >
                    <MenuIcon sx={{ color: 'white', }} />
                </Fab>
            </Box>

            {open && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        background: 'black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 3,
                        animation: `${animationState} 0.5s ease-in-out`,
                        '@keyframes slideIn': {
                            from: { transform: 'translateY(-100%)' },
                            to: { transform: 'translateY(0)' },
                        },
                        '@keyframes slideOut': {
                            from: { transform: 'translateY(0)' },
                            to: { transform: 'translateY(-100%)' },
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            maxWidth: '500px',
                            textAlign: 'center',
                        }}
                    >
                        <List sx={{ width: '100%', padding: 0 }}>
                            {settings.map((setting, index) => (
                                <ListItem
                                    key={index}
                                    button
                                    sx={{
                                        color: 'white',
                                        cursor: 'pointer',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            background: 'rgba(0, 0, 0, 0.05)',
                                        },
                                    }}
                                    onClick={() => handlePageSelect(setting)}
                                >
                                    <Typography variant="h6">{setting}</Typography>
                                </ListItem>
                            ))}
                        </List>
                        <Button
                            onClick={handleClose}
                            sx={{
                                mt: 2,
                                backgroundColor: 'transparent',
                                color: 'white',
                                padding: 0,
                                boxShadow: 'none',
                                minWidth: 'auto',
                                minHeight: 'auto',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    opacity: 0.8,
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                </Box>
            )}
        </AppBar>
    );
}

export default FloatingButton;
