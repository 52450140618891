import axios from "axios"
import configs from  "./endpoints.json"

const userAwsService = axios.create({
    baseURL: `${configs.userAwsService}`
})

const userService = axios.create({
    baseURL: `${configs.userService}`
})

const ApiInstance = {
    userAwsService,
    userService
}

export default ApiInstance;